import {
  Autocomplete,
  Box,
  Chip,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";

export default function SegmentBox({ channel, segments, segmentRef }) {
  const [segment, setSegment] = React.useState(segmentRef.current);

  React.useEffect(() => {
    segmentRef.current = segment;
  }, [segment]);

  const isArray = segments[0].values;

  const SegmentListElement = ({ options, value, onChange, title, multiple = false }) => {
    return (
      <Autocomplete
        multiple={multiple}
        style={{ width: "90%", marginBottom: 8, marginRight: 20, background: "white" }}
        options={options}
        getOptionLabel={(option) => option.name}
        groupBy={(option) => option.group}
        value={multiple
          ? value?.map(val => options.find(op => op.id === val?.id) ?? null).filter(op => op) ?? []
          : options.find(op => op.id === value?.id)
        }
        onChange={onChange}
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        renderTags={(value, getTagProps) => (
          <div className="chipBlock"
            style={value[0]?.title ? { maxHeight: "140px" } : { maxHeight: "60px", overflow: "scroll" }}
          >
            {value.map((option, index) => {
              return <Chip
                sx={{ borderRadius: '6px', background: '#f7f7f7', border: '1px solid #EAEAEC' }}
                label={<span>{option.name ?? option.title}</span>}
                size="small"
                {...getTagProps({ index })}
              />
            })}
          </div>
        )}
        renderInput={(params) => {
          params.label = title ?? "New segment";
          return <TextField sx={{ '& > div': { flexWrap: "nowrap !important" }, '& input': { height: "25px" } }} {...params} size="small" placeholder="Select" />
        }}
      />
    );
  };

  return (
    <Box
      sx={{
        marginTop: "20px",
        padding: "16px 24px",
        borderRadius: "16px",
        background: "#F6F7F8",
      }}>
      <p className="h18">{channel.properties.segmentBoxTitle ?? "Segments"}</p>
      {isArray ?
        <div style={{ display: "flex" }}>
          {segments.map((s, index) =>
            <div style={{ width: "33%" }}>
              <Typography style={{ fontSize: "14px", marginBottom: "8px" }}>{s.name}</Typography>
              <SegmentListElement
                key={index}
                options={s.values}
                value={segment[index]}
                multiple={s.name === "SKad Network API"}
                title={""}
                onChange={(event, newValue) => {
                  const newSegments = Array.from(segment);
                  newSegments[index] = newValue;
                  setSegment(newSegments);
                }}
              />
            </div>
          )}
        </div>
        : <div style={{ width: "33%" }}>
          <SegmentListElement options={segments} value={segment} onChange={(event, newValue) => setSegment(newValue)} />
        </div>
      }
    </Box>
  );
}
