import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.scss";
import RouterContainer from "./RouterContainer";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/lib/integration/react";
import { store, persistor } from "./redux/store";
import { FpjsProvider } from '@fingerprintjs/fingerprintjs-pro-react';
import ChannelAuthenticator from "./ChannelAuthenticator";
import { BrowserAgent } from '@newrelic/browser-agent/loaders/browser-agent'

const options = {
  init: {
    distributed_tracing: { enabled: true },
    privacy: { cookies_enabled: true },
    ajax: { deny_list: ["bam.eu01.nr-data.net"] }
  },
  info: { beacon: "bam.eu01.nr-data.net", errorBeacon: "bam.eu01.nr-data.net", licenseKey: "NRJS-681981dacd4560c633f", applicationID: "538468620", sa: 1 },
  loader_config: { accountID: "3898929", trustKey: "3898929", agentID: "538468620", licenseKey: "NRJS-681981dacd4560c633f", applicationID: "538468620" }
}

new BrowserAgent(options);

export const REDUX_VERSION = 2;

ReactDOM.render(
  <FpjsProvider
    loadOptions={{
      apiKey: process.env.REACT_APP_FINGERPRINT_API_KEY, region: process.env.REACT_APP_FINGERPRINT_REGION
    }}
  >
    <Provider store={store}>
      <React.StrictMode>
        <PersistGate loading={<>Loading...</>} persistor={persistor}>
          <BrowserRouter>
            <App>
              <ChannelAuthenticator>
                <RouterContainer />
              </ChannelAuthenticator>
            </App>
          </BrowserRouter>
        </PersistGate>
      </React.StrictMode>
    </Provider>
  </FpjsProvider>,
  document.getElementById("root")
);
