import React, { Suspense, forwardRef, lazy, useImperativeHandle } from "react";
import IconButton from "@mui/material/IconButton";
import { Box, Button, Menu, MenuItem, Tooltip } from "@mui/material";
import Alert from "@mui/material/Alert";
import SixDots from "../../Assets/Images/logos_sixdots.svg";
import GraphLogo from "../../Assets/Images/logos_graph.svg";
import ThreeDots from "../../Assets/Images/logos_threedots.svg";
import TableLogo from "../../Assets/Images/logos_table.svg";
import DrilledTableLogo from "../../Assets/Images/logos_drilled_table.svg";
import AddChartLogo from "../../Assets/Images/logos_add_chart.svg";
import { ReactComponent as DeleteIcon } from "../../Assets/Images/Delete.svg";
import { ReactComponent as FilterIcon } from "../../Assets/Icons/filterIcon.svg";
import { ReactComponent as NoAccountIcon } from "../../Assets/errorMsgImages/NoAccount.svg";
import { ReactComponent as SelectViewIdIcon } from "../../Assets/errorMsgImages/SelectViewId.svg";
import { ReactComponent as DefaultErrorIcon } from "../../Assets/errorMsgImages/DefaultError.svg";
import { ReactComponent as NoDataIcon } from "../../Assets/errorMsgImages/NoData.svg";
import { ReactComponent as SelectionErrorIcon } from "../../Assets/errorMsgImages/Selection.svg";
import { ReactComponent as MappingDeletedIcon } from "../../Assets/errorMsgImages/MappingDeleted.svg";
import { ReactComponent as TypeMissingIcon } from "../../Assets/errorMsgImages/TypeMissing.svg";
import { ReactComponent as RedirectIcon } from "../../Assets/Icons/openFilter.svg";
import ChartLoader from "../Common/Loader";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import Vector from "../../Assets/Icons/Vector.svg";
import { getAccountBasedColumns, getWidgetData } from "../../api/channel/saga";
import { getBlendColumns, getBlendWidgetData } from "../../api/blends/saga";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { Chart, Filter } from "../../models/chart";
import { ChannelType, GetChannel } from "../../models/channel";
import { updateChart } from "../../api/report/action";
import { useHistory, useParams } from "react-router";
import { DateDisplayFormatter, dataBlendSource, ChartFilterSupport, GetColor, } from "./ChartUtils";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import * as htmlToImage from "html-to-image";
import { getFilterDetails, getFilterObject } from "../Filteration/Utils";
import { Errorhandler } from "../ErrorHanlder/ErrorHandler";
import "../styles/charts.css";
import { buttonClickSaveAndUpdate } from "../../api/channel/action";
import { EVENTS, transformMixPanelData } from "../../utils/mixpanel";
import { updateLoader } from "../../api/user/action";
import { ReactComponent as EditIcon } from "../../Assets/Icons/edit-icon.svg";
import { ReactComponent as ExportIcon } from "../../Assets/Icons/export-icon.svg";
import { ReactComponent as DeleteNewIcon } from "../../Assets/Icons/delete-icon.svg";
import TreemapChartWrapper from "./TreeMapChartWrapper";

const TrendingCharts = lazy(() => import("./TrendingCharts"));
const TrendingCharts3D = lazy(() => import("./TrendingCharts3D"));
const GrouppingCharts = lazy(() => import("./GroupingCharts"));
const KPIChart = lazy(() => import("./KPIChart"));
const DrillDownTable = lazy(() => import("./DrillDownTable"));
const BubbleChart = lazy(() => import("./BubbleChart"));
const ScatterChart = lazy(() => import("./ScatterChart"));
const TreemapChart = lazy(() => import("./TreemapChart"));
const HeatmapChart = lazy(() => import("./HeatMap"));
const WaterfallChart = lazy(() => import("./WaterfallCharts"));
const WordCloudChart = lazy(() => import("./WordcloudChart"));
const Geochart = lazy(() => import("./GeoChart"));

const ChartWrapper = forwardRef((props, ref) => {
  const {
    chart: initialChart,
    handleToggleEditDrawer,
    closeEditDrawer,
    removeChart,
    report,
  } = props;
  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();
  const _updateChart = (_) => dispatch(updateChart(_));
  const { channel: reportId, tab: tabPath } = useParams();
  const [chart, setChart] = React.useState(initialChart);
  const [chartCopy, setChartCopy] = React.useState(chart);
  const [channel, setChannel] = React.useState(GetChannel(chart.channelType));
  const [channelCols, setChannelCols] = React.useState(null);
  const [account, setAccount] = React.useState(chart.account);
  const [accountCols, setAccountCols] = React.useState(null);
  const [updateDataCount, setUpdateDataCount] = React.useState(0);
  const authenticatedAccounts = useSelector(
    (state) => state.channel.authenticatedAccounts
  );
  const selectedAccounts = useSelector(
    (state) => state.channel.selectedAccounts
  );
  const dateRange = useSelector((state) => state.dashboard.dateRange);
  const state = useSelector((state) => channel.getState(state));
  const wholeState = useSelector((state) => state);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [title, setTitle] = React.useState(chart.title);
  const [chartStyle, setChartStyle] = React.useState(chart.chartStyle);
  const [isTable, setIsTable] = React.useState(false);
  const [isDrillTable, setIsDrillTable] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isCompareLoading, setIsCompareLoading] = React.useState(false);
  const [comparisonVal, setComparisonVal] = React.useState("");
  const [drillData, setDrillData] = React.useState({
    drillIndex: 1,
    selected: [],
  });
  const [data, setData] = React.useState({
    dimension: "",
    metrics: [],
    data: [],
  });
  const [comparedData, setComparedData] = React.useState({
    dimension: [] || "",
    leftMetrics: [],
    rightMetrics: [],
    data: [],
  });
  const [currentChartType, setCurrentChartType] = React.useState(chart.type);
  const [isAddChart, setIsAddChart] = React.useState(chart.type === "ADD");
  const [editChart, setEditChart] = React.useState(false);
  const [exportData, setExportData] = React.useState(false);
  const [getData, setGetData] = React.useState(true);
  const history = useHistory();

  React.useEffect(() => {
    if (!chart.isEqual(initialChart)) {
      setChart(initialChart);
      setChannel(GetChannel(initialChart.channelType));
      setAccount(initialChart.account);
      setTitle(initialChart.title);
      setChartStyle(initialChart.chartStyle);
      updateChartFilters();
    }
  }, []);

  React.useEffect(() => {
    if (
      chart.gridPosition.x !== initialChart.gridPosition.x || chart.gridPosition.y !== initialChart.gridPosition.y ||
      chart.gridPosition.w !== initialChart.gridPosition.w || chart.gridPosition.h !== initialChart.gridPosition.h
    ) {
      chart.gridPosition = initialChart.gridPosition;
    }
  }, [initialChart.gridPosition])

  React.useEffect(() => {
    if (!channel.isBlend && account) {
      getAccountBasedColumns(account, channel)
        .then((data) => {
          let columns = data?.columns ?? [];
          setAccountCols({
            metrics: columns.filter((c) => c.type === "METRIC"),
            dimensions: columns.filter((c) => c.type === "DIMENSION"),
          });
        })
        .catch((err) => {
          setAccountCols({ metrics: [], dimensions: [] });
        });
    } else {
      setAccountCols(null);
    }
  }, [account]);

  React.useEffect(() => {
    if (channel.isBlend) {
      getBlendColumns(channel.type)
        .then((data) => {
          let columns = (data?.columns ?? []).map((c) => ({
            id: c.id,
            name: `${c.uiName} (${GetChannel(c.dataSource).title})`,
            group: c.group,
            dataType: c.dataType,
            type: c.type,
            dataSource: c.dataSource,
            miscInfo: c.miscInfo,
          }));
          setChannelCols({
            dimensions: columns.filter((c) => c.type === "DIMENSION"),
            metrics: columns
              .filter((c) => c.type === "METRIC")
              .concat(wholeState.blends.blendCustomCols[channel.type] ?? []),
          });
        })
        .catch((err) => {
          setChannelCols({ metrics: [], dimensions: [] });
        });
    } else {
      setChannelCols({ metrics: [], dimensions: [] });
    }
  }, [channel]);

  const [reloadCount, setReloadCount] = React.useState(0);

  const [initialUpdate, setInitialUpdate] = React.useState(true);

  React.useEffect(() => {
    if (!initialUpdate) {
      if (
        channel.type === (chart.channelType?.id ?? chart.channelType) &&
        currentChartType === chart.type
      ) {
        updateData();
      }
    } else {
      setInitialUpdate(false);
    }
  }, [
    state.metricsUpdated,
    // state?.tablesUpdated?.[chart.table?.id],
    dateRange,
    chart,
    reloadCount,
    drillData.drillIndex,
    isDrillTable,
  ]);

  React.useEffect(() => {
    setChartCopy(Chart.fromJSON(chart.toJSON()));
  }, [chart]);

  // React.useEffect(() => {
  //   if (
  //     chart.table &&
  //     state.metricsUpdated &&
  //     state.tablesUpdated &&
  //     !state.tablesUpdated[chart.table?.id]
  //   ) {
  //     dispatch(channel?.actions?.getColumns(chart.table));
  //   }
  // }, [chart.table]);

  React.useEffect(() => {
    if (
      getData &&
      channel.type === (chart.channelType?.id ?? chart.channelType) &&
      currentChartType === chart.type
    ) {
      updateChartFilters();
    }
  }, [updateDataCount]);

  React.useEffect(() => {
    setDrillData({ drillIndex: 1, selected: [] });
  }, [isDrillTable]);

  const forceUpdate = () => {
    setDrillData({ drillIndex: 1, selected: [] });
    transformMixPanelData(EVENTS.refresh_chart, chart);
    setIsAddChart(false);
    setUpdateDataCount((prev) => prev + 1);
  };

  const insertInNamingMap = (namingMap, metric) => {
    if (!metric) {
      return;
    }
    const key = metric.dataSource ?? chart.channelType;
    if (!namingMap.get(key)) {
      namingMap.set(key, new Map());
    }
    namingMap.get(key).set(metric.id, metric.name);
  };

  const updateFilter = ([filter]) => {
    handleChartChange(
      "filter",
      !filter || filter.error
        ? Filter.new({ channelType: chart.channelType })
        : filter,
      true
    );
  };

  const updateMetricFilter = (filters) => {
    let updateChart = false;
    const leftMetrics = chart.leftMetrics.map((metric) => {
      const filter =
        metric.filter.id &&
        filters.find((filter) => filter.id === metric.filter.id);
      if (filter) {
        metric.filter = filter;
      } else {
        if (!updateChart && metric.filter.id) {
          updateChart = true;
        }
        metric.filter = Filter.new({ channelType: chart.channelType });
      }
      return metric;
    });

    const rightMetrics = chart.rightMetrics?.map((metric) => {
      const filter =
        metric.filter.id &&
        filters.find((filter) => filter.id === metric.filter.id);
      if (filter && filter.id && !filter.error) {
        metric.filter = filter;
      } else {
        if (!updateChart && metric.filter.id) {
          updateChart = true;
        }
        metric.filter = Filter.new({ channelType: chart.channelType });
      }
      return metric;
    });
    chart.leftMetrics = leftMetrics.filter((metric) => metric && metric.metric);
    chart.rightMetrics = rightMetrics?.filter(
      (metric) => metric && metric.metric
    );

    const chartObj = chart.toJSON();
    setChart(Chart.fromJSON(chartObj));
    if (updateChart) {
      const paramKeys = ["leftMetrics", "rightMetrics"];
      const chartUpdateObj = chart.toJSONfiltered();
      _updateChart({
        reportId: reportId,
        updates: [
          {
            chart_id: chart.id,
            update_type: "update",
            params: paramKeys
              .map((param) => ({ [param]: chartUpdateObj[param] ?? null }))
              .reduce((obj, curr) => ({ ...obj, ...curr }), {}),
          },
        ],
      });
    }
  };

  const updateChartFilters = () => {
    if (ChartFilterSupport(currentChartType)) {
      if (chart?.filter?.id) {
        getFilterDetails([chart.filter.id], channel.type, updateFilter);
      } else {
        updateData();
      }
    } else {
      const combinedMetrics = [
        ...chart.leftMetrics,
        ...(chart.rightMetrics ?? []),
      ];

      const allFilters = combinedMetrics
        .map((metric) => metric.filter.id)
        .filter((id) => id);
      if (allFilters.length) {
        getFilterDetails(allFilters, channel.type, (filters) =>
          updateMetricFilter(filters)
        );
      } else {
        updateData();
      }
    }
  };

  const updateDrillTableData = (
    startDate,
    endDate,
    callback,
    callbackData,
    compare
  ) => {
    compare ? setIsCompareLoading(true) : setIsLoading(true);
    const combinedMetrics = [
      ...chart.leftMetrics,
      ...(chart.rightMetrics ?? []),
    ];

    if (combinedMetrics.length === 0) {
      callback({ ...callbackData, error: "No metrics are selected" });
      compare ? setIsCompareLoading(false) : setIsLoading(false);
      return;
    }

    const unitKey = channel.properties.unitKey ?? "dataType";
    const namingMap = new Map();
    for (const metric of combinedMetrics) {
      insertInNamingMap(namingMap, metric?.metric);
    }
    chart.dimensions?.forEach((dimension) =>
      insertInNamingMap(namingMap, dimension)
    );

    var promise;
    if (channel.isBlend) {
      promise = getBlendWidgetData(channel.type, [
        {
          startDate: startDate,
          endDate: endDate,
          accounts: channel.transformer.getAccountId(
            account ?? selectedAccounts,
            state
          ),
          ...channel.transformer.getApiObject(
            combinedMetrics
              .map((metric) => metric.metric)
              ?.filter((metric) => metric?.id),
            chart.dimensions
              ?.filter((dim) => dim?.id)
              .slice(0, drillData.drillIndex),
            null,
            chart.table
          ),
          ...getFilterObject(
            {
              ...chart.filter,
              dimensionFilters: [
                ...drillData.selected.map((sel) => ({
                  boolOperator: "AND",
                  filter: sel.dimKey,
                  operator: { type: "EQUALS" },
                  value: [sel.value],
                })),
                ...chart.filter.dimensionFilters,
              ],
            },
            compare
          ),
        },
      ]);
    } else {
      promise = getWidgetData(
        `${user.org?.name || user.company_name || user.org?.id}|${report || reportId
        }|${tabPath}|${chart.title || chart.id}`,
        [
          {
            startDate: startDate,
            endDate: endDate,
            ...channel.transformer.getAccountId(
              account ?? selectedAccounts[channel.type]
            ),
            ...channel.transformer.getApiObject(
              combinedMetrics.map((metric) => metric.metric.id),
              chart.dimensions
                .map((dimension) => dimension.id)
                .slice(0, drillData.drillIndex),
              Filter.new({ channelType: channel.type }),
              chart.table,
              chart.dataLevel,
              chart.excludeDeleted
            ),
            ...getFilterObject(
              {
                ...chart.filter,
                dimensionFilters: [
                  ...drillData.selected.map((sel) => ({
                    boolOperator: "AND",
                    filter: sel.dimKey,
                    operator: { type: "EQUALS" },
                    value: [sel.value],
                  })),
                  ...chart.filter.dimensionFilters,
                ],
              },
              compare
            ),
            orderBys: chart.dimensions
              .map((dimension) => ({
                fieldName: dimension.id,
                sortOrder: "ASC",
              }))
              .slice(0, drillData.drillIndex),
            dataSource: channel.type,
          },
        ]
      );
    }
    promise
      .then((data) => {
        const segmentIndex = data[0].columns.findIndex(
          (c) => c === "ga:segment"
        );
        const dataDimensions = chart.dimensions
          .map((dim) => ({ ...dim }))
          .slice(drillData.drillIndex - 1, drillData.drillIndex);
        const mappedData = data[0].values.map((value) => {
          const entry = new Map();
          data[0].columns.forEach((column, index) => {
            const displayName = namingMap
              .get(data[0].dataSources?.[index] ?? chart.channelType)
              .get(column);
            if (displayName === "Name") {
              const columnName = column
                .replaceAll("_", " ")
                .replaceAll(".", " ")
                .split(" ")
                .map((name) => name[0].toUpperCase() + name.slice(1))
                .join(" ");
              let dimIndex = dataDimensions.findIndex((d) => d.id === column);
              if (dimIndex !== -1 && dataDimensions[dimIndex]?.name)
                dataDimensions[dimIndex].name = columnName;
              entry.set(columnName, value[index]);
            } else if (index !== segmentIndex) {
              entry.set(displayName, value[index]);
            }
          });
          chart.dimensions.forEach((d) => {
            if (!data[0].columns.includes(d.id)) {
              let index = data[0].columns.findIndex(
                (c) => c === d.miscInfo?.base_dimension?.id
              );
              if (index !== -1) entry.set(d.name, value[index]);
            }
          });
          return Object.fromEntries(entry.entries());
        });
        const dataObject = {
          ...callbackData,
          error: "",
          dimensions: dataDimensions,
          leftMetrics: combinedMetrics.map((metric) => ({
            id: metric.metric.id,
            name: metric.metric.name,
            unit: metric.metric[unitKey],
            currency: account
              ? account.currency
                ? account.currency
                : dataBlendSource(metric.metric.name)
                  ? account[dataBlendSource(metric.metric.name)]?.currency
                  : undefined
              : state.currency
                ? state.currency
                : dataBlendSource(metric.metric.name)
                  ? wholeState[dataBlendSource(metric.metric.name)]?.currency
                  : undefined,
          })),
          data: mappedData,
        };
        channel.transformer.transformData?.(dataObject);
        callback(dataObject);
      })
      .catch((err) => {
        let errorStatus = err?.data?.responses?.error?.status;
        let error = err?.data?.error;
        if (
          err.status === 400 ||
          err.status === 422 ||
          errorStatus === 400 ||
          errorStatus === 422
        ) {
          callback({ error: "Invalid query", canReload: true });
        } else if (err.status === 500 && err.data === "Invalid Blend ID") {
          callback({ error: "This source is not supported anymore" });
        } else if (
          err.status === 500 &&
          error?.message?.includes?.("No Data Mapping Found")
        ) {
          callback({
            error:
              "Mapping Does not exists for current accounts for added Custom Dimensions in this source. Please Refresh Your Mapping!",
          });
        } else if (
          err.status === 500 &&
          error.message?.includes?.("No Custom Dimension Found")
        ) {
          callback({
            error:
              "Some of the Custom Dimensions added in this source are deleted. Please update source dimensions and keys!",
          });
        } else {
          console.log("Error", chart, err);
          callback({ error: "Something went wrong!", canReload: true });
        }
      })
      .finally(() => {
        compare ? setIsCompareLoading(false) : setIsLoading(false);
      });
  };

  const updateTableTypeData = (
    startDate,
    endDate,
    callback,
    callbackData,
    compare
  ) => {
    compare ? setIsCompareLoading(true) : setIsLoading(true);
    const combinedMetrics = [
      ...chart.leftMetrics,
      ...(chart.rightMetrics ?? []),
    ];

    if (combinedMetrics.length === 0) {
      callback({ ...callbackData, error: "No metrics are selected" });
      compare ? setIsCompareLoading(false) : setIsLoading(false);
      return;
    }

    const unitKey = channel.properties.unitKey ?? "dataType";
    const namingMap = new Map();
    for (const metric of combinedMetrics) {
      insertInNamingMap(namingMap, metric?.metric);
    }
    chart.dimensions?.forEach((dimension) =>
      insertInNamingMap(namingMap, dimension)
    );

    var promise;
    if (channel.isBlend) {
      promise = getBlendWidgetData(channel.type, [
        {
          startDate: startDate,
          endDate: endDate,
          accounts: channel.transformer.getAccountId(
            account ?? selectedAccounts,
            state
          ),
          ...channel.transformer.getApiObject(
            combinedMetrics
              .map((metric) => metric.metric)
              ?.filter((metric) => metric?.id),
            chart.dimensions?.filter((dim) => dim?.id),
            chart.filter,
            chart.table
          ),
        },
      ]);
    } else {
      promise = getWidgetData(
        `${user.org?.name || user.company_name || user.org?.id}|${report || reportId
        }|${tabPath}|${chart.title || chart.id}`,
        [
          {
            startDate: startDate,
            endDate: endDate,
            ...channel.transformer.getAccountId(
              account ?? selectedAccounts[channel.type]
            ),
            ...channel.transformer.getApiObject(
              combinedMetrics.map((metric) => metric.metric.id),
              chart.dimensions.map((dimension) => dimension.id),
              chart.filter,
              chart.table,
              chart.dataLevel,
              chart.excludeDeleted
            ),
            orderBys: chart.dimensions.map((dimension) => ({
              fieldName: dimension.id,
              sortOrder: "ASC",
            })),
            dataSource: channel.type,
          },
        ]
      );
    }
    promise
      .then((data) => {
        const segmentIndex = data[0].columns.findIndex(
          (c) => c === "ga:segment"
        );
        const dataDimensions = chart.dimensions.map((d) => d.name);
        const mappedData = data[0].values.map((value) => {
          const entry = new Map();
          data[0].columns.forEach((column, index) => {
            const displayName = namingMap
              .get(data[0].dataSources?.[index] ?? chart.channelType)
              .get(column);
            if (displayName === "Name") {
              const columnName = column
                .replaceAll("_", " ")
                .replaceAll(".", " ")
                .split(" ")
                .map((name) => name[0].toUpperCase() + name.slice(1))
                .join(" ");
              dataDimensions[
                chart.dimensions.findIndex((d) => d.id === column)
              ] = columnName;
              entry.set(columnName, value[index]);
            } else if (index !== segmentIndex) {
              entry.set(displayName, value[index]);
            }
          });
          chart.dimensions.forEach((d) => {
            if (!data[0].columns.includes(d.id)) {
              let index = data[0].columns.findIndex(
                (c) => c === d.miscInfo?.base_dimension?.id
              );
              if (index !== -1) entry.set(d.name, value[index]);
            }
          });
          return Object.fromEntries(entry.entries());
        });
        const dataObject = {
          dimensions: dataDimensions,
          leftMetrics: combinedMetrics.map((metric) => ({
            id: metric.metric.id,
            name: metric.metric.name,
            unit: metric.metric[unitKey],
            currency: account
              ? account.currency
                ? account.currency
                : dataBlendSource(metric.metric.name)
                  ? account[dataBlendSource(metric.metric.name)]?.currency
                  : undefined
              : state.currency
                ? state.currency
                : dataBlendSource(metric.metric.name)
                  ? wholeState[dataBlendSource(metric.metric.name)]?.currency
                  : undefined,
          })),
          data: mappedData,
        };
        channel.transformer.transformData?.(dataObject);
        callback(dataObject);
      })
      .catch((err) => {
        let errorStatus = err?.data?.responses?.error?.status;
        let error = err?.data?.error;
        if (
          err.status === 400 ||
          err.status === 422 ||
          errorStatus === 400 ||
          errorStatus === 422
        ) {
          callback({ error: "Invalid query" });
        } else if (err.status === 500 && err.data === "Invalid Blend ID") {
          callback({ error: "This source is not supported anymore" });
        } else if (
          err.status === 500 &&
          error?.message?.includes?.("No Data Mapping Found")
        ) {
          callback({
            error:
              "Mapping Does not exists for current accounts for added Custom Dimensions in this source. Please Refresh Your Mapping!",
          });
        } else if (
          err.status === 500 &&
          error?.message?.includes?.("No Custom Dimension Found")
        ) {
          callback({
            error:
              "Some of the Custom Dimensions added in this source are deleted. Please update source dimensions and keys!",
          });
        } else {
          console.log("Error", chart, err);
          callback({ error: "Something went wrong!", canReload: true });
        }
      })
      .finally(() => {
        compare ? setIsCompareLoading(false) : setIsLoading(false);
      });
  };

  const updateData = () => {
    setData({ ...data, error: "" });
    setTitle(chart.title);
    setIsLoading(true);
    setEditChart(false);

    // check if channel is unauthenticated
    if (channel.isBlend) {
      if (
        !wholeState.blends.blends.find((blend) => blend.id === channel.type)
      ) {
        setData({
          error: "This source is not supported anymore",
          footerError: true,
        });
        setIsLoading(false);
        return;
      }
    } else {
      if (!authenticatedAccounts.find((type) => type === channel.type)) {
        setData({
          error: "No account connected",
          footerError: true,
          redirect: "/managesources",
        });
        setIsLoading(false);
        return;
      }
    }

    if (
      (channel.isBlend && !channelCols) ||
      (!channel.isBlend && account && !accountCols)
    ) {
      setTimeout(() => {
        setReloadCount((prev) => prev + 1);
      }, 5 * 1000);
      return;
    }

    const combinedMetrics = [
      ...chart.leftMetrics,
      ...(chart.rightMetrics ?? []),
    ];

    if (combinedMetrics.length === 0) {
      setData({ ...data, error: "No metrics are selected" });
      setIsLoading(false);
      return;
    }

    if (!state.metricsUpdated) {
      setData({
        error:
          "Metrics not updated for the channel! Please wait for some time, or try refreshing the page!",
      });
      setIsLoading(false);
      return;
    }

    // if (state.tablesUpdated && !state.tablesUpdated[chart.table?.id]) {
    //   setData({
    //     error:
    //       "Metrics not updated for the selected table in this channel! Please wait for some time, or try refreshing the page!",
    //   });
    //   setIsLoading(false);
    //   return;
    // }

    channel.updateGridFields(
      chart,
      state.tables,
      !Array.isArray(state.metrics)
        ? state.metrics
        : channel.isBlend
          ? channelCols.metrics
          : state.metrics.concat(
            accountCols ? accountCols.metrics : state.customMetrics ?? []
          ),
      !Array.isArray(state.dimensions)
        ? state.dimensions
        : channel.isBlend
          ? channelCols.dimensions
          : state.dimensions.concat(
            accountCols ? accountCols.dimensions : state.customDimensions ?? []
          )
    );

    setIsAddChart(false);
    handleClose();
    setEditChart(true);

    //check if required accounts selected for channels
    if (channel.isBlend) {
      if (account && Object.keys(account).length) {
        const customAccounts = channel.transformer.getAccountId(account, state);
        for (let acc in customAccounts) {
          if (!(acc in account)) {
            setData({
              error:
                "Either remove current custom selection or select all required account IDs for blend",
            });
            setIsLoading(false);
            return;
          }
        }
      } else {
        const accounts = channel.transformer.getAccountId(
          selectedAccounts,
          state
        );
        for (let account in accounts) {
          if (!(account in selectedAccounts)) {
            setData({ error: "Required account id has not been selected" });
            setIsLoading(false);
            return;
          }
        }
      }
    } else if (!(channel.type in selectedAccounts) && !account) {
      setData({
        error:
          'Please select view id from "manage data source" or "custom selection" within edit widget to populate the chart.',
      });
      setIsLoading(false);
      return;
    }

    if (chart.type === "ADD") {
      setData({ error: "Chart type not selected" });
      setIsLoading(false);
      return;
    }

    const unitKey = channel.properties.unitKey ?? "dataType";

    const namingMap = new Map();

    for (const metric of combinedMetrics) {
      if (!metric?.metric) {
        if (combinedMetrics.length === 1) {
          setData({
            error: `No field selected. ${channel.isBlend
              ? "Please check the mapping once within data blend source."
              : ""
              }`,
          });
        } else {
          setData({
            error: `Some selected metric fields doesn't exist. ${channel.isBlend
              ? "Please check the mapping once within data blend source."
              : "It may exist on some other account."
              }`,
          });
        }
        setIsLoading(false);
        return;
      }
      insertInNamingMap(namingMap, metric?.metric);
    }

    for (const [index, dimension] of (chart.dimensions ?? []).entries()) {
      if (!["PIE", "PIE3D", "DONUT", "DONUT3D", "FUNNEL", "KPI"].includes(currentChartType) && !dimension?.id) {
        setData({
          error: `Some selected dimension fields doesn't exist. ${channel.isBlend
            ? "Please check the mapping once within data blend source."
            : "It may exist on some other account."
            }`,
        });
        setIsLoading(false);
        return;
      }
      insertInNamingMap(namingMap, dimension);
    }
    chart.dimensions?.forEach((dimension) =>
      namingMap.set(dimension?.id, dimension?.name)
    );
    if (getData) {
      if (currentChartType === "TABLE" && isDrillTable) {
        updateDrillTableData(
          dateRange.startDate,
          dateRange.endDate,
          setData,
          data
        );
      } else if (ChartFilterSupport(currentChartType)) {
        updateTableTypeData(
          dateRange.startDate,
          dateRange.endDate,
          setData,
          data
        );
      } else {
        updateChartData(dateRange.startDate, dateRange.endDate, setData, data);
      }
      updateCompareWithDate(chart.compareWith);
    } else {
      setGetData(true);
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setExportData(false);
  };

  const handleRemoveChart = () => {
    dispatch(buttonClickSaveAndUpdate("REMOVE_CHART"));
    handleChartChange("removeChart", "ADD");
    handleClose();
    closeEditDrawer(chart);
  };

  const saveAs = (blob, fileName) => {
    var elem = window.document.createElement("a");
    elem.href = blob;
    elem.download = fileName;
    elem.style = "display:none;";
    (document.body || document.documentElement).appendChild(elem);
    if (typeof elem.click === "function") {
      elem.click();
    } else {
      elem.target = "_blank";
      elem.dispatchEvent(
        new MouseEvent("click", {
          view: window,
          bubbles: true,
          cancelable: true,
        })
      );
    }
    URL.revokeObjectURL(elem.href);
    elem.remove();
  };

  const padding = 15;
  let top = 15;
  const elHeight = 80;
  const elWidth = 150;
  const maxColCount = 5;

  const createPdfData = async (doc, top) => {
    if (isTable || chart.type === "TABLE") {
      const headers = [
        ...drillData.selected.map((d) => d.dimKey.name),
        ...(data.dimensions ?? []).map((d) => d?.name ?? d),
        ...data.leftMetrics.map((m) => m.name + (m.filter ? " (" + m.filter + ")" : "")),
        ...(data.rightMetrics?.map((m) => m.name + (m.filter ? " (" + m.filter + ")" : "")) ?? []),
      ];
      for (let count = 0; count < headers.length; count = count + maxColCount) {
        if (headers.length > maxColCount) {
          doc.text(["Table:: Part " + (count / maxColCount + 1)], padding, top);
          top += 10;
        }
        const filteredHeaders = headers.filter(
          (h, index) => index >= count && index < count + maxColCount
        );
        var rows = data.data.map((row, rowIndex) => {
          let obj = [rowIndex + 1];
          for (let header of filteredHeaders) {
            obj.push(row[header]);
          }
          return obj;
        });
        doc.autoTable({
          startY: top,
          head: [["Sr. No.", ...filteredHeaders]],
          body: rows,
          columnStyles: {
            0: {
              columnWidth: 20,
              fontStyle: "bold",
              halign: "center",
            },
          },
        });
        top = doc.previousAutoTable.finalY + 10;
      }
    } else {
      const imgData = await htmlToImage.toPng(
        document.getElementById("Chart" + chart.id),
        { quality: 1.0, pixelRatio: 1 }
      );
      doc.addImage(
        imgData,
        "PNG",
        padding,
        top,
        elWidth,
        elHeight,
        `image${chart.id}`
      );
      top += elHeight + padding;
    }
    return top;
  };

  useImperativeHandle(ref, () => ({
    forceUpdateChart() {
      forceUpdate();
    },
    handleAllExport() {
      if (data && data?.data?.length > 0) {
        var headers;
        var rows;
        var title = ["Title:", chart.title];
        if (currentChartType === "PIE" || currentChartType === "PIE3D" || currentChartType === "DONUT" || currentChartType === "DONUT3D" || currentChartType === "FUNNEL") {
          headers = Object.keys(data.data[0]);
          rows = [Object.values(data.data[0])];
        } else {
          headers = [
            ...drillData.selected.map((d) => d.dimKey.name),
            ...(data.dimensions ?? []).map((d) => d?.name ?? d),
            ...data.leftMetrics.map((m) => m.name),
            ...(data.rightMetrics?.map((m) => m.name) ?? []),
          ];
          rows = data.data.map((row) => headers.map((h) => row[h]));
        }
        return [title, [], headers, ...rows];
      }
    },
    async handleAllPdfExport(doc, top) {
      if (data && data?.data?.length > 0) {
        if (top > 200) {
          top = 15;
          doc.addPage();
        }
        var title = `Title:  ${chart.title}`;
        doc.text([title], padding, top);
        top += 10;
        top = await createPdfData(doc, top);
      }
      return top;
    },
    handleAllPngExport() {
      if (!data || !data.data || data.data.length === 0) return;
      onCapturePNG();
    },
    handleAllJpgExport() {
      if (!data || !data.data || data.data.length === 0) return;
      onCaptureJPG();
    },
  }));

  const onCapturePNG = () => {
    const name = chart.title
      .replace(/[^a-zA-Z0-9 ]/g, "")
      .replaceAll(" ", "-")
      .toLowerCase();
    htmlToImage
      .toPng(document.getElementById("Chart" + chart.id), {
        quality: 1.0,
        pixelRatio: 1,
      })
      .then(function (dataUrl) {
        saveAs(dataUrl, `${name}.png`);
      });
    handleClose();
  };

  const onCaptureJPG = () => {
    const name = chart.title
      .replace(/[^a-zA-Z0-9 ]/g, "")
      .replaceAll(" ", "-")
      .toLowerCase();
    htmlToImage
      .toJpeg(document.getElementById("Chart" + chart.id), {
        backgroundColor: "white",
        quality: 1.0,
        pixelRatio: 1,
      })
      .then(function (dataUrl) {
        saveAs(dataUrl, `${name}.jpeg`);
      });
    handleClose();
  };

  const handleExport = () => {
    dispatch(updateLoader(true));
    var headers;
    var rows;
    var title = ["Title:", chart.title];
    var date = [
      "Date Range: ",
      DateDisplayFormatter(dateRange.startDate) +
      " - " +
      DateDisplayFormatter(dateRange.endDate),
    ];
    // if (currentChartType === "PIE" || currentChartType === "PIE3D" || currentChartType === "DONUT" || currentChartType === "DONUT3D" || currentChartType === "FUNNEL") {
    //   headers = Object.keys(data.data[0]);
    //   rows = [Object.values(data.data[0])];
    // } else {

    headers = [
      ...drillData.selected.map((d) => d.dimKey.name),
      ...(data.dimensions ?? []).map((d) => d?.name ?? d),
      ...data.leftMetrics.map((m) => m.name + (m.filter ? " (" + m.filter + ")" : "")),
      ...(data.rightMetrics?.map((m) => m.name + (m.filter ? " (" + m.filter + ")" : "")) ?? []),
    ];
    rows = data.data.map((row) => headers.map((h) => row[h]));

    const blob = new Blob([[title, date, [], headers, ...rows].join("\n")], {
      type: "text/csv",
    });
    const a = document.createElement("a");
    a.download = chart.title
      .replace(/[^a-zA-Z0-9 ]/g, "")
      .replaceAll(" ", "-")
      .toLowerCase();
    a.href = window.URL.createObjectURL(blob);
    a.dispatchEvent(
      new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: true,
      })
    );
    a.remove();

    handleClose();
    dispatch(updateLoader(false));
  };

  const handlePdfExport = async () => {
    dispatch(updateLoader(true));
    const name = chart.title
      .replace(/[^a-zA-Z0-9 ]/g, "")
      .replaceAll(" ", "-")
      .toLowerCase();
    const doc = new jsPDF({});
    var title = `Title:  ${chart.title}`;
    var date = `Date Range:  ${DateDisplayFormatter(
      dateRange.startDate
    )} - ${DateDisplayFormatter(dateRange.endDate)}`;
    doc.text([title, date], padding, top);
    top += 20;
    top = await createPdfData(doc, top);
    doc.save(`${name}.pdf`);
    handleClose();
    dispatch(updateLoader(false));
  };

  const updateChartData = (
    startDate,
    endDate,
    callback,
    callbackData,
    compare
  ) => {
    compare ? setIsCompareLoading(true) : setIsLoading(true);
    const combinedMetrics = [
      ...chart.leftMetrics,
      ...(chart.rightMetrics ?? []),
    ];

    if (combinedMetrics.length === 0) {
      callback({ ...callbackData, error: "No metrics are selected" });
      compare ? setIsCompareLoading(false) : setIsLoading(false);
      return;
    }

    const unitKey = channel.properties.unitKey ?? "dataType";
    const namingMap = new Map();
    for (const metric of combinedMetrics) {
      insertInNamingMap(namingMap, metric?.metric);
    }
    chart.dimensions?.forEach((dimension) =>
      insertInNamingMap(namingMap, dimension)
    );

    var promise;
    if (channel.isBlend) {
      promise = getBlendWidgetData(channel.type, [
        {
          startDate: startDate,
          endDate: endDate,
          accounts: channel.transformer.getAccountId(
            account ?? selectedAccounts,
            state
          ),
          ...channel.transformer.getApiObject(
            combinedMetrics
              .map((metric) => metric.metric)
              ?.filter((metric) => metric?.id),
            chart.dimensions?.filter((dim) => dim?.id),
            combinedMetrics[0]?.filter,
            chart.table
          ),
        },
      ]);
    } else {
      promise = getWidgetData(
        `${user.company_name || user.org?.id}|${report || reportId
        }|${tabPath}|${chart.title || chart.id}`,
        combinedMetrics.map((metric) => ({
          startDate: startDate,
          endDate: endDate,
          ...channel.transformer.getAccountId(
            account ?? selectedAccounts[channel.type]
          ),
          ...channel.transformer.getApiObject(
            [metric.metric?.id],
            chart.dimensions
              ?.filter((d) => d)
              .map((dimension) => dimension?.id) ?? [],
            metric.filter,
            chart.table,
            chart.dataLevel,
            chart.excludeDeleted
          ),
          orderBys:
            chart.dimensions?.map((dimension) => ({
              fieldName: dimension?.id,
              sortOrder: "ASC",
            })) ?? [],
          dataSource: channel.type,
        }))
      );
    }
    promise
      .then((chartData) => {
        const rightMetricsIndex = chart.leftMetrics.length;
        const totalMetrics = combinedMetrics.length;
        const dimensionIndex =
          chart.dimensions && chart.dimensions.length > 0
            ? chartData[0].columns.findIndex(
              (c) =>
                c === chart.dimensions[0]?.id ||
                c === chart.dimensions[0]?.miscInfo?.base_dimension?.id
            )
            : -1;
        const totalRows = chartData.reduce(
          (prevValue, currentValue) =>
            Math.min(prevValue, currentValue.rowCount),
          chartData[0].rowCount
        );
        const totalMaxRows = chartData.reduce(
          (prevValue, currentValue) =>
            Math.max(prevValue, currentValue.rowCount),
          chartData[0].rowCount
        );
        const dataLeftMetrics = chart.leftMetrics.map((metric) => ({
          id: metric.metric.id,
          name: metric.metric.name,
          unit: metric.metric[unitKey],
          currency: account
            ? account.currency
              ? account.currency
              : dataBlendSource(metric.metric.name) ? account[dataBlendSource(metric.metric.name)]?.currency : undefined
            : state.currency
              ? state.currency
              : dataBlendSource(metric.metric.name) ? wholeState[dataBlendSource(metric.metric.name)]?.currency : undefined,
          filter: metric.filter.name
        }));
        const dataRightMetrics = chart.rightMetrics?.map((metric) => ({
          id: metric.metric.id,
          name: metric.metric.name,
          unit: metric.metric[unitKey],
          currency: account
            ? account.currency
              ? account.currency
              : dataBlendSource(metric.metric.name) ? account[dataBlendSource(metric.metric.name)]?.currency : undefined
            : state.currency
              ? state.currency
              : dataBlendSource(metric.metric.name) ? wholeState[dataBlendSource(metric.metric.name)]?.currency : undefined,
          filter: metric.filter.name
        }));

        let mappedData = [];
        let metricIndex = [];
        for (let i = 0; i < totalMetrics; i++) {
          metricIndex.push(
            chartData[channel.isBlend ? 0 : i].columns.findIndex(
              (c, ci) =>
                c === combinedMetrics[i].metric.id &&
                chartData[channel.isBlend ? 0 : i].dataSources?.[ci] ===
                combinedMetrics[i].metric.dataSource
            )
          );
        }

        let dimWiseData = {};
        if (dimensionIndex >= 0 && totalRows !== totalMaxRows) {
          for (let [mIndex, metric] of chartData.entries()) {
            let column = metric.columns[0];
            if (!combinedMetrics[mIndex].filter.isEmpty()) {
              const filterName = combinedMetrics[mIndex].filter.name;
              //Updating name in chart metrics data only once
              if (mIndex >= rightMetricsIndex) {
                dataRightMetrics[mIndex - rightMetricsIndex].id += " (" + filterName + ")";
              } else {
                dataLeftMetrics[mIndex].id += " (" + filterName + ")";
              }
              column = column + " (" + filterName + ")";
            }

            for (let value of metric.values) {
              if (dimWiseData[value[dimensionIndex]]) {
                dimWiseData[value[dimensionIndex]][column] = value[0];
              } else {
                dimWiseData[value[dimensionIndex]] = { [column]: value[0] };
              }
            }
          }

          let dimName =
            namingMap
              .get(
                chartData[0].dataSources?.[dimensionIndex] ?? chart.channelType
              )
              .get(chartData[0].columns[dimensionIndex]) ??
            chart.dimensions[0].name;

          for (let row of Object.keys(dimWiseData)) {
            let entry = new Map();
            entry.set(dimName, row);

            for (let mIndex = 0; mIndex < totalMetrics; mIndex++) {
              const index = metricIndex[mIndex];
              let metricData = chartData[channel.isBlend ? 0 : mIndex];
              let column = metricData.columns[index];
              let metricId = chartData[channel.isBlend ? 0 : mIndex].columns[index];
              let metricName = namingMap.get(chartData[channel.isBlend ? 0 : mIndex].dataSources?.[index] ?? chart.channelType)
                .get(chartData[channel.isBlend ? 0 : mIndex].columns[index]);
              if (!combinedMetrics[mIndex].filter.isEmpty()) {
                const filterName = combinedMetrics[mIndex].filter.name;
                column += " (" + filterName + ")";
                metricName += " (" + filterName + ")";
                metricId += " (" + filterName + ")"
              }
              let value = dimWiseData[row][column] ?? "-";
              entry.set(metricName, value ?? "-");
              entry.set(metricId, value ?? "-");
            }
            mappedData.push(Object.fromEntries(entry));
          }
        } else {
          for (let row = 0; row < totalRows; row++) {
            let entry = new Map();

            if (dimensionIndex >= 0) {
              const value = chartData[0].values[row][dimensionIndex];
              let dimName =
                namingMap
                  .get(
                    chartData[0].dataSources?.[dimensionIndex] ??
                    chart.channelType
                  )
                  .get(chartData[0].columns[dimensionIndex]) ??
                chart.dimensions[0].name;
              entry.set(dimName, value);
            }

            for (let mIndex = 0; mIndex < totalMetrics; mIndex++) {
              const index = metricIndex[mIndex];
              const value =
                chartData[channel.isBlend ? 0 : mIndex].values[row][index];
              const metricId = chartData[channel.isBlend ? 0 : mIndex].columns[index];
              const metricName = namingMap.get(chartData[channel.isBlend ? 0 : mIndex].dataSources?.[index] ?? chart.channelType)
                .get(chartData[channel.isBlend ? 0 : mIndex].columns[index]);

              // if metric has filter, insert using filter name
              if (!combinedMetrics[mIndex].filter.isEmpty()) {
                const filterName = combinedMetrics[mIndex].filter.name;
                //Upddating name only once
                if (row === 0) {
                  if (mIndex >= rightMetricsIndex) {
                    dataRightMetrics[mIndex - rightMetricsIndex].id += " (" + filterName + ")";
                  } else {
                    dataLeftMetrics[mIndex].id += " (" + filterName + ")";
                  }
                }
                entry.set(metricName + " (" + filterName + ")", value);
                entry.set(metricId + " (" + filterName + ")", value);
              } else {
                entry.set(metricName, value);
                entry.set(metricId, value);
              }
            }
            mappedData.push(Object.fromEntries(entry));
          }
        }
        const dataObject = {
          dimensions: chart.dimensions?.map((d) => d?.name || ""),
          leftMetrics: dataLeftMetrics,
          rightMetrics: dataRightMetrics,
          data: mappedData,
        };
        channel.transformer.transformData?.(dataObject);

        callback(dataObject);
      })
      .catch((err) => {
        let errorStatus = err?.data?.responses?.error?.status;
        let error = err?.data?.error;
        if (
          [400, 422].includes(err.status) ||
          errorStatus === 400 ||
          errorStatus === 422
        ) {
          callback({ error: "Invalid query" });
        } else if (err.status === 500 && err.data === "Invalid Blend ID") {
          callback({ error: "This source is not supported anymore" });
        } else if (
          err.status === 500 &&
          error?.message?.includes?.("No Data Mapping Found")
        ) {
          setData({
            error:
              "Mapping Does not exists for current accounts for added Custom Dimensions in this source. Please Refresh Your Mapping!",
          });
        } else if (
          err.status === 500 &&
          error?.message?.includes?.("No Custom Dimension Found")
        ) {
          setData({
            error:
              "Some of the Custom Dimensions added in this source are deleted. Please update source dimensions and keys!",
          });
        } else {
          console.log("Error", chart, err);
          callback({ error: "Something went wrong!", canReload: true });
        }
      })
      .finally(() => {
        compare ? setIsCompareLoading(false) : setIsLoading(false);
      });
  };

  const updateCompareWithDate = (value) => {
    setComparisonVal(value);
    if (!value) {
      setComparedData({
        data: [],
        leftMetrics: [],
        rightMetrics: [],
        dimensions: [],
      });
    } else if (value.indexOf("_") > -1) {
      const dates = value.split("_");
      const startDate = dates[0];
      const endDate = dates[1];

      if (chart.type === "TABLE" && isDrillTable)
        updateDrillTableData(
          startDate,
          endDate,
          setComparedData,
          comparedData,
          true
        );
      else if (chart.type === "TABLE")
        updateTableTypeData(
          startDate,
          endDate,
          setComparedData,
          comparedData,
          true
        );
      else
        updateChartData(
          startDate,
          endDate,
          setComparedData,
          comparedData,
          true
        );
    } else {
      const startDate = moment(dateRange.startDate, "YYYY-MM-DD")
        .subtract(1, value)
        .format("YYYY-MM-DD");
      const endDate = moment(dateRange.endDate, "YYYY-MM-DD")
        .subtract(1, value)
        .format("YYYY-MM-DD");

      if (chart.type === "TABLE" && isDrillTable)
        updateDrillTableData(
          startDate,
          endDate,
          setComparedData,
          comparedData,
          true
        );
      else if (chart.type === "TABLE")
        updateTableTypeData(
          startDate,
          endDate,
          setComparedData,
          comparedData,
          true
        );
      else
        updateChartData(
          startDate,
          endDate,
          setComparedData,
          comparedData,
          true
        );
    }
  };

  const handleChartChange = (property, value, addChart) => {
    var updated = false;
    var paramKeys = [];
    switch (property) {
      case "compareWith":
        if (chart.compareWith !== value) {
          chart.compareWith = value;
        }
        break;
      case "title":
        if (chart.title !== value) {
          chart.title = value;
          chartCopy.title = value;
          setTitle(value);
        }
        break;
      case "dimensions":
        if (
          chart.dimensions?.length === value.length &&
          chart.dimensions?.every((dimension, index) =>
            Chart.isDimensionEqual(dimension, value[index])
          )
        ) {
        } else {
          chart.dimensions = value;
        }
        break;
      case "leftMetrics":
        if (
          chart.leftMetrics.length === value.length &&
          chart.leftMetrics.every((metric, index) =>
            metric.isEqual(value[index])
          )
        ) {
        } else {
          chart.leftMetrics = value;
        }
        break;
      case "rightMetrics":
        if (!chart.rightMetrics) {
          break;
        }
        if (
          chart.rightMetrics.length === value.length &&
          chart.rightMetrics.every((metric, index) =>
            metric.isEqual(value[index])
          )
        ) {
        } else {
          chart.rightMetrics = value;
        }
        break;
      case "filter":
        if (!chart.filter.isEqualAll(value)) {
          if (addChart && !chart.filter.isEqual(value)) {
            paramKeys = ["filter"];
          }
          chart.filter = value;
        }
        updated = !!addChart;
        break;
      case "table":
        if (chart.table?.id !== value?.id) {
          chart.copyValues(
            Chart.new(
              chart.channelType,
              chart.title,
              chart.type,
              value,
              chart.gridPosition,
              [],
              undefined,
              undefined,
              undefined,
              chart.account
            )
          );
        }
        break;
      case "removeChart":
        if (chart.type !== value) {
          setCurrentChartType(value);
          if (value === "ADD") {
            setIsAddChart(true);
            chart.copyValues(
              Chart.new(
                ChannelType.Default,
                "",
                value,
                null,
                { ...chart.gridPosition, minW: 3, minH: 1 }
              )
            );
            const chartObj = chart.toJSON();
            delete chartObj.id;
            delete chartObj.gridPosition;
            paramKeys = Object.keys(chartObj);
            updated = true;
          }
        }
        break;
      case "type":
        if (chart.type !== value) {
          setCurrentChartType(value);
          if (value === "ADD" || isAddChart || addChart) {
            setIsAddChart(true);
            chart.copyValues(
              Chart.new(
                chart.channelType,
                "",
                value,
                null,
                {
                  ...chart.gridPosition,
                  w: (!["ADD", "KPI"].includes(value) && chart.gridPosition.w < 6 ? 6 : chart.gridPosition.w),
                  h: (!["ADD", "KPI"].includes(value) && chart.gridPosition.h < 2 ? 2 : chart.gridPosition.h),
                  minW: ["ADD", "KPI"].includes(value) ? 3 : 6,
                  minH: ["ADD", "KPI"].includes(value) ? 1 : 2
                },
                [],
                undefined,
                undefined,
                undefined,
                chart.account
              )
            );
            if (value === "ADD") {
              const chartObj = chart.toJSON();
              delete chartObj.id;
              delete chartObj.channel;
              // delete chartObj.gridPosition;
              paramKeys = Object.keys(chartObj);
              updated = true;
            }
          } else {
            chart.type = value;
            chartCopy.type = value;
            paramKeys = ["type"];
          }
        }
        break;
      case "channel":
        if (
          chart.channelType !== value.type &&
          value.type !== chart.channelType.id
        ) {
          setIsAddChart(true);
          setChannel(value);
          setAccount(null);
          chart.copyValues(
            Chart.new(
              value.isBlend
                ? { id: value.type, name: value.title }
                : value.type,
              "",
              chart.type,
              null,
              chart.gridPosition
            )
          );
        }
        break;
      case "account":
        if (
          (typeof chart.channelType !== "object" &&
            chart.account?.title !== value?.title) ||
          (typeof chart.channelType === "object" &&
            (Object.keys(value ?? {}).length !==
              Object.keys(chart.account ?? {}).length ||
              !Object.keys(value ?? {}).every(
                (dataSource) =>
                  value[dataSource]?.title ===
                  chart.account?.[dataSource]?.title
              )))
        ) {
          setIsAddChart(true);
          setAccount(value);
          chart.copyValues(
            Chart.new(
              chart.channelType,
              "",
              chart.type,
              null,
              chart.gridPosition,
              [],
              undefined,
              undefined,
              undefined,
              value
            )
          );
        }
        break;
      case "chartStyle":
        if (chart.chartStyle !== value) {
          chart.chartStyle = { ...chart.chartStyle, ...value };
          chartCopy.chartStyle = { ...chart.chartStyle, ...value };
          setChartStyle(chart.chartStyle);
        }
        break;
      case "dataLevel":
        if (chart.dataLevel !== value) {
          chart.dataLevel = value;
        }
        break;
      case "excludeDeleted":
        if (chart.excludeDeleted !== value) {
          chart.excludeDeleted = value;
        }
        break;
      case "saveChanges":
        const chartObj = chart.toJSON();
        delete chartObj.id;
        if (chart.gridPosition.w === chartCopy.gridPosition.w && chart.gridPosition.h === chartCopy.gridPosition.h) {
          delete chartObj.gridPosition;
        }
        delete chartObj.gridId;
        paramKeys = Object.keys(chartObj);
        updated = true;
        break;
      case "close":
        paramKeys = [];
        if (chart.title !== initialChart.title) {
          paramKeys.push("title");
        }
        if (
          !Object.keys(chart.chartStyle).every(
            (key) =>
              JSON.stringify(chart.chartStyle[key]) ===
              JSON.stringify(initialChart.chartStyle[key])
          )
        ) {
          paramKeys.push("chartStyle");
        }
        if (!chart.isEqual(chartCopy)) {
          setGetData(false);
          chart.copyValues(Chart.fromJSON(chartCopy.toJSON()));
          setIsAddChart(chartCopy.type === "ADD");
          setChart(Chart.fromJSON(chart.toJSON()));
          setCurrentChartType(chartCopy.type);
          setChannel(GetChannel(chartCopy.channelType));
          setAccount(chartCopy.account);
        }
        break;
      default:
        break;
    }
    chart.validate();
    if (updated) {
      chartCopy.copyValues(Chart.fromJSON(chart.toJSON()));
      setChart(Chart.fromJSON(chart.toJSON()));
      setDrillData({ drillIndex: 1, selected: [] });
      // updateCompareWithDate(chart.compareWith);
    }
    if (paramKeys.length > 0) {
      const chartObj = chart.toJSONfiltered();
      _updateChart({
        reportId: reportId,
        updates: [
          {
            chart_id: chart.id,
            update_type: "update",
            params: paramKeys
              .map((param) => ({ [param]: chartObj[param] ?? null }))
              .reduce((obj, curr) => ({ ...obj, ...curr }), {}),
          },
        ],
      });
    }
  };

  const getErrorImage = (error) => {
    switch (error) {
      case "No account connected":
        return <NoAccountIcon />;
      case 'Please select view id from "manage data source" or "custom selection" within edit widget to populate the chart.':
      case "Required account id has not been selected":
      case "No field selected. ":
      case "No field selected. Please check the mapping once within data blend source.":
        return <SelectViewIdIcon />;
      case "Either remove current custom selection or select all required account IDs for blend":
      case "Some selected metric fields doesn't exist. Please check the mapping once within data blend source.":
      case "Some selected dimension fields doesn't exist. Please check the mapping once within data blend source.":
      case "Some selected metric fields doesn't exist. It may exist on some other account.":
      case "Some selected dimension fields doesn't exist. It may exist on some other account.":
        return <SelectionErrorIcon />;
      case "Some of the custom dimensions added in this source are deleted. Please update source dimensions and keys!":
        return <MappingDeletedIcon />;
      case "Chart type not selected":
        return <TypeMissingIcon />;
      default:
        return <DefaultErrorIcon />;
    }
  };

  const redirect_connect_source = (data) => {
    transformMixPanelData(EVENTS.connect_sources_chart, chart);
    history.push(data.redirect);
  };

  const getChartObject = (type, data) => {
    try {
      if (!data.data || data.error) {
        return (
          <div
            style={{
              margin: "auto",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            {getErrorImage(data.error)}
            <h3 className="lato " style={{ margin: "10px" }}>
              {data.error}
            </h3>
            {(data.canReload || data.redirect) && (
              <Button
                style={{
                  textTransform: "none",
                  borderRadius: "6px",
                  color: "#0869FB",
                  textDecoration: data.redirect ? "underline" : "none",
                }}
                startIcon={
                  data.canReload ? <AutorenewIcon /> : <RedirectIcon />
                }
                onClick={() => {
                  data.redirect ? redirect_connect_source(data) : forceUpdate();
                }}
              >
                {data.canReload ? "Refresh" : "Connect Data Sources"}
              </Button>
            )}
          </div>
        );
      } else if (
        (currentChartType === "GEO" &&
          (!chart.dimensions?.[0] ||
            (!chart.dimensions[0].name.toLowerCase().includes("country") && chart.channelType !== "appsflyer")
            || (chart.channelType === "appsflyer" && !chart.dimensions[0].name.toLowerCase().includes("geo"))
          )
        )
      ) {
        return (
          <div
            style={{
              margin: "auto",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <MappingDeletedIcon />
            <h3 className="lato" style={{ margin: "10px" }}>
              First Dimension must be country field for GEO chart.
            </h3>
          </div>
        );
      }

      if (chart?.compareWith && !comparedData.data) {
        // Handle Compared Data not received error
        console.log("Compared Data not received");
      }

      if (drillData.drillIndex <= 1 && data.data.length === 0) {
        return (
          <div
            style={{
              margin: "auto",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              height: "100%",
            }}
          >
            <NoDataIcon />
            <h3 className="lato" style={{ margin: "10px" }}>
              Nothing to be displayed on this view id
            </h3>
          </div>
        );
      }

      switch (type) {
        default:
        case "LINE":
        case "BAR":
        case "STACKBAR":
        case "LINEBAR":
        case "AREA":
        case "COLUMN":
          return <TrendingCharts data={data} comparedData={comparedData} chartStyle={chartStyle} type={type} />;
        case "BAR3D":
        case "STACKBAR3D":
          return <TrendingCharts3D data={data} comparedData={comparedData} chartStyle={chartStyle} type={type} />;
        case "PIE":
        case "PIE3D":
        case "DONUT":
        case "DONUT3D":
        case "FUNNEL":
          return <GrouppingCharts data={data} chartStyle={chartStyle} type={type} />;
        case "KPI":
          return <KPIChart data={data} comparedData={comparedData} chartStyle={chartStyle} type={channel.type} />;
        case "TABLE":
          return <DrillDownTable data={data} comparedData={comparedData} drillData={drillData} setDrillData={setDrillData} chartStyle={chartStyle} type={channel.type} isTable={isTable} isDrillTable={isDrillTable} chartDimensions={chart.dimensions} compareWith={comparisonVal} />;
        case "BUBBLE":
          return <BubbleChart data={data} chartStyle={chartStyle} type={channel.type} />
        case "SCATTER":
          return <ScatterChart data={data} chartStyle={chartStyle} type={channel.type} />
        case "WORDCLOUD":
          return <WordCloudChart data={data} chartStyle={chartStyle} type={channel.type} />
        case "TREEMAP":
          return (
            <TreemapChartWrapper>
              {({ HighchartsInstance }) => (
                <TreemapChart data={data} chartStyle={chartStyle} type={channel.type} HighchartsInstance={HighchartsInstance} />
              )}
            </TreemapChartWrapper>
          )
        case "HEATMAP":
          return <HeatmapChart data={data} chartStyle={chartStyle} type={channel.type} />
        case "WATERFALL":
          return <WaterfallChart data={data} chartStyle={chartStyle} type={channel.type} />
        case "GEO":
          return <Geochart data={data} chartStyle={chartStyle} type={channel.type} />
        case "ADD":
          return <div />;
      }
    } catch (e) {
      return <Alert severity="error">Error loading chart</Alert>;
    }
  };

  let titleHeight = chart.chartStyle.titleStyle.fontSize <= 40 ? 40 : (8 + Number(chart.chartStyle.titleStyle.fontSize));

  return (
    <>
      {!isAddChart ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingBottom: "0",
              height: titleHeight,
            }}
          >
            {currentChartType !== "KPI" ? (
              <Tooltip title={title} placement="top-start">
                <p
                  style={{
                    fontSize: chart.chartStyle.titleStyle.fontSize,
                    lineHeight: (8 + Number(chart.chartStyle.titleStyle.fontSize)) + "px",
                    fontFamily: chart.chartStyle.titleStyle.font,
                    fontWeight: 550,
                    fontStyle:
                      chart.chartStyle.titleStyle.fontFormat.includes(
                        "italic"
                      ) && "italic",
                    textDecorationLine:
                      chart.chartStyle.titleStyle.fontFormat.includes(
                        "underline"
                      ) && "underline",
                    color: chart.chartStyle.titleStyle.color,
                    width: "45%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    textAlign: "left",
                    margin: 0
                  }}
                >
                  {title}
                </p>
              </Tooltip>
            ) : (
              <div style={{ width: "45%" }} />
            )}

            <div className="drag-handle" style={{ width: "10%" }}>
              <img src={SixDots} alt="dots" />
            </div>

            <div
              style={{
                display: "flex",
                width: "45%",
                justifyContent: "flex-end",
                paddingBottom: "10px",
              }}
            >
              {currentChartType !== "KPI" && (
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    marginRight: "10px",
                    cursor: "pointer",
                  }}
                  aria-label="settings"
                >
                  {currentChartType !== "TABLE" &&
                    currentChartType !== "KPI" && (
                      <div
                        onClick={() => setIsTable(false)}
                        style={{
                          display: "flex",
                          border: "1px solid #EAEAEC",
                          padding: 5,
                          borderRadius: 4,
                          height: "30px",
                        }}
                      >
                        <img
                          height={18}
                          src={GraphLogo}
                          alt="graph"
                          className={
                            isTable ? "not-selected-svg" : "selected-svg"
                          }
                        />
                      </div>
                    )}

                  <div
                    onClick={() => {
                      currentChartType === "TABLE"
                        ? setIsDrillTable(true)
                        : setIsTable(true);
                    }}
                    style={{
                      display: "flex",
                      border: "1px solid #EAEAEC",
                      padding: 5,
                      borderRadius: 4,
                      height: "30px",
                    }}
                  >
                    <img
                      height={18}
                      src={TableLogo}
                      alt="table"
                      className={
                        (currentChartType !== "TABLE" && isTable) ||
                          (currentChartType === "TABLE" && isDrillTable)
                          ? "selected-svg"
                          : "not-selected-svg"
                      }
                    />
                  </div>

                  {currentChartType === "TABLE" && (
                    <div
                      onClick={() => setIsDrillTable(false)}
                      style={{
                        display: "flex",
                        border: "1px solid #EAEAEC",
                        padding: 5,
                        borderRadius: 4,
                        height: "30px",
                      }}
                    >
                      <img
                        height={18}
                        src={DrilledTableLogo}
                        alt="drilled_table"
                        className={
                          !isDrillTable ? "selected-svg" : "not-selected-svg"
                        }
                      />
                    </div>
                  )}
                </div>
              )}

              {chart?.compareWith && (
                <div style={{ width: "32px", display: "flex", marginRight: '10px' }}>
                  <Tooltip
                    arrow
                    title={
                      chart.compareWith.indexOf("_") > -1
                        ? chart.compareWith.replace("_", " to ")
                        : comparisonVal
                    }
                    placement="top"
                  >
                    <div
                      style={{
                        display: "flex",
                        height: "30px",
                        alignItems: "center",
                        justifyContent: "center",
                        border: "1px solid #eaeaec",
                        width: "30px",
                        borderRadius: 4,
                        cursor: "pointer",
                        // marginRight: "10px",
                      }}
                    >
                      <img
                        src={Vector}
                        style={{ width: 18 }}
                        alt="Comparison Vector"
                      />
                    </div>
                  </Tooltip>
                </div>
              )}

              <div data-tut="edit_chart">
                <div
                  onClick={(e) => setAnchorEl(e.currentTarget)}
                  style={{
                    display: "flex",
                    height: "30px",
                    alignItems: "center",
                    justifyContent: "center",
                    border: "1px solid #eaeaec",
                    width: "30px",
                    borderRadius: 4,
                    cursor: "pointer",
                  }}
                >
                  <IconButton
                    disableRipple
                    style={{ padding: 0, height: "100%" }}
                    onClick={(e) => setAnchorEl(e.currentTarget)}
                  >
                    <img src={ThreeDots} alt="dots" />
                  </IconButton>
                </div>

                <Menu
                  id="menu-appbar"
                  className="smooth-shadow"
                  anchorEl={anchorEl}
                  keepMounted
                  getContentAnchorEl={null}
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  transformOrigin={{ vertical: "top", horizontal: "right" }}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  sx={{
                    marginTop: "10px",
                    "& 		.MuiMenu-paper": {
                      borderRadius: "8px",
                      padding: "8px",
                      minWidth: "168px",
                      gap: "12px",
                    },
                    "& .MuiMenuItem-root": {
                      fontFamily: "Inter",
                      fontSize: "14px",
                      fontWeight: "400",
                      lineHeight: "20px",
                    },
                  }}
                >
                  {!exportData ? (
                    <>
                      <Tooltip
                        title={
                          !(editChart || !isLoading)
                            ? "Wait for the chart to load"
                            : ""
                        }
                        placement="top"
                        arrow
                      >
                        <div>
                          <MenuItem
                            data-tut="edit_chart_options"
                            onClick={() => {
                              dispatch(
                                buttonClickSaveAndUpdate("UPDATE_CHART")
                              );
                              handleToggleEditDrawer(chart, handleChartChange);
                              handleClose();
                            }}
                            disabled={!(editChart || !isLoading)}
                          >
                            <EditIcon
                              style={{
                                marginRight: "12px",
                                width: "20px",
                                height: "20px",
                              }}
                            />{" "}
                            Edit Widget
                          </MenuItem>
                        </div>
                      </Tooltip>
                      <MenuItem onClick={handleRemoveChart}>
                        <DeleteNewIcon
                          style={{
                            marginRight: "12px",
                          }}
                        />
                        Remove Chart
                      </MenuItem>
                      <MenuItem
                        onClick={() => setExportData(true)}
                        disabled={!data || !data.data || data.data.length === 0}
                      >
                        <ExportIcon
                          style={{
                            marginRight: "12px",
                          }}
                        />{" "}
                        Export
                      </MenuItem>
                    </>
                  ) : (
                    <>
                      <MenuItem
                        style={{
                          paddingLeft: "10px",
                          color: "#00000099",
                          fontSize: "14px",
                        }}
                        onClick={() => setExportData(false)}
                      >
                        <ArrowBackIcon style={{ width: "16px" }} />
                        <p style={{ margin: "auto 5px", fontWeight: "bold" }}>
                          {" "}
                          Export
                        </p>{" "}
                      </MenuItem>
                      <MenuItem
                        style={{ paddingLeft: "30px" }}
                        onClick={handlePdfExport}
                      >
                        PDF Format
                      </MenuItem>
                      <MenuItem
                        style={{ paddingLeft: "30px" }}
                        onClick={onCapturePNG}
                      >
                        PNG Format
                      </MenuItem>
                      <MenuItem
                        style={{ paddingLeft: "30px" }}
                        onClick={onCaptureJPG}
                      >
                        JPG Format
                      </MenuItem>
                      <MenuItem
                        style={{ paddingLeft: "30px" }}
                        onClick={handleExport}
                      >
                        CSV Format
                      </MenuItem>
                    </>
                  )}
                </Menu>
              </div>
            </div>
          </div>
          {isLoading || isCompareLoading ? (
            <ChartLoader />
          ) : (
            <div
              id={"Chart" + chart.id}
              style={{
                height: `calc(100% - ${titleHeight + 20}px)`,
                width: "auto",
                display: "flex",
                overflow: "hidden",
              }}
            >
              <Errorhandler errorMessage="Error Loading Chart">
                <Suspense fallback={<ChartLoader />}>
                  {getChartObject(isTable ? "TABLE" : currentChartType, data)}
                </Suspense>
              </Errorhandler>
            </div>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "10px",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <img src={channel.icon} height="20" width="20" />
              {!data.footerError && (
                <Tooltip
                  PopperProps={{
                    sx: {
                      "& .MuiTooltip-arrow": {
                        color: "white",
                      },
                      "& .MuiTooltip-tooltip": {
                        backgroundColor: "white",
                        color: "black",
                        marginBottom: "20px",
                        filter: "drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.25))",
                        padding: "10px 25px",
                      },
                    },
                  }}
                  title={
                    !channel.isBlend ? (
                      account?.title
                    ) : (
                      <Box sx={{ borderRadius: "10px" }}>
                        {Object.keys(account ?? {}).map(
                          (channelType, index) => {
                            const channel = GetChannel(channelType);
                            return (
                              <div
                                style={{ borderRadius: "4px", padding: "3px" }}
                                key={channelType}
                                value={channel.name}
                              >
                                <img
                                  height={16}
                                  width={16}
                                  src={channel.icon}
                                  alt={channel.title}
                                />
                                <span style={{ paddingLeft: "15px" }}>
                                  {account[channelType].title}
                                </span>
                              </div>
                            );
                          }
                        )}
                      </Box>
                    )
                  }
                  arrow
                  placement="top-end"
                >
                  <p className="inter bold account-footer">
                    {!channel.isBlend
                      ? account?.title
                      : Object.values(account ?? {})
                        .map((acc) => acc?.title)
                        .join(", ")}
                  </p>
                </Tooltip>
              )}
            </div>
            {!data.footerError &&
              (chart.filter?.id ||
                chart.leftMetrics.filter(
                  (metric) => metric.metric && metric.filter?.name
                ).length ||
                chart.rightMetrics?.filter(
                  (metric) => metric.metric && metric.filter?.name
                )?.length) ? (
              <Tooltip
                PopperProps={{
                  sx: {
                    "& .MuiTooltip-arrow": { color: "white" },
                    "& .MuiTooltip-tooltip": {
                      backgroundColor: "white",
                      color: "black",
                      marginBottom: "20px",
                      filter: "drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.25))",
                      padding: "10px 25px",
                    },
                  },
                }}
                title={
                  <Box sx={{ borderRadius: "10px" }}>
                    {ChartFilterSupport(currentChartType) ? (
                      <>
                        <span style={{ color: "#787878" }}>Filter: </span>
                        <span
                          style={{
                            fontSize: "12px",
                            fontFamily: "Inter",
                            fontWeight: "400",
                          }}
                        >
                          {chart.filter?.name}{" "}
                        </span>
                      </>
                    ) : (<>
                      {
                        (chart.leftMetrics.concat(chart.rightMetrics ?? [])).map((metric, index) => {
                          if (metric.metric && metric.filter?.name) {
                            return <>
                              <span style={{ color: "#787878" }}>Metric: </span>
                              <span style={{ fontSize: "12px", fontFamily: "Inter", fontWeight: "400", color: GetColor(index, chart?.chartStyle.palette) }}>{metric?.metric?.name}, </span>
                              <span style={{ color: "#787878" }}>Filter: </span>
                              <span style={{ fontSize: "12px", fontFamily: "Inter", fontWeight: "400", color: GetColor(index, chart?.chartStyle.palette) }}>{metric.filter.name} </span>
                              <br />
                            </>
                          } else {
                            return "";
                          }
                        })
                      }
                    </>
                    )}
                  </Box>
                }
                arrow
                placement="top-end"
              >
                <FilterIcon style={{ padding: "2px" }} />
              </Tooltip>
            ) : null}
          </div>
        </>
      ) : (
        <div
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <IconButton
            onClick={removeChart}
            style={{ position: "absolute", right: "1.5vw", top: "2vh" }}
          >
            <DeleteIcon />
          </IconButton>
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              dispatch(buttonClickSaveAndUpdate("CREATE_NEW_CHART"));
              handleChartChange("type", "ADD");
              handleToggleEditDrawer(chart, handleChartChange);
            }}
          >
            <img src={AddChartLogo} alt="Add Chart" />
            <br />
            <h4 style={{ marginTop: "10px" }} className="inter">
              Add Chart
            </h4>
          </div>
        </div>
      )}
    </>
  );
});

export default ChartWrapper;
